import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
declare var window;
var db;
var databaseHandler;
var productHandler;

@Injectable({
  providedIn: 'root'
})
export class OfflineStorageService {

  constructor(
  ) { }

  backgroundGEOLocation() {
    document.addEventListener("deviceready", function () {
      //this.createDB();
    });
  }

  databaseHandler = {
    db: null,
    createDatabase: function (self) {
      console.log('SQL INIT BEGIN!!!');
      setTimeout(() => {
        
        let dbName = 'cuteAsset_dev_101';
        db = window.sqlitePlugin.openDatabase({ name: 'db6.db', location: 2, createFromLocation: 1});
  
        // db = window.openDatabase(dbName + ".db", "1.0", dbName + " database", 1000000);
        db.transaction(
          function (tx) {
            tx.executeSql('CREATE TABLE IF NOT EXISTS loginSettings (_id INTEGER PRIMARY KEY, userpass TEXT, loginData TEXT)');
            tx.executeSql("CREATE TABLE IF NOT EXISTS rfiInspection(pid INTEGER PRIMARY KEY,_id TEXT, inspectionData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS equipment(pid INTEGER PRIMARY KEY,_id TEXT, equipmentData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS workflow(pid INTEGER PRIMARY KEY,_id TEXT, inspectionId TEXT, workflowData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS attachment(pid INTEGER PRIMARY KEY,_id TEXT, refID TEST,module TEXT, attachmentData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS qrCode(pid INTEGER PRIMARY KEY,_id TEXT, qrCodeData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS rfiReport(pid INTEGER PRIMARY KEY,_id TEXT,rfiID TEXT, rfiReportData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS location(pid INTEGER PRIMARY KEY,_id TEXT, locationData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS manufacture(pid INTEGER PRIMARY KEY,_id TEXT, manufactureData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS type(pid INTEGER PRIMARY KEY,_id TEXT, typeData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS equipmentCompany(pid INTEGER PRIMARY KEY,_id TEXT, equipmentCompanyData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS checklist(pid INTEGER PRIMARY KEY,_id TEXT, checklistData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS scheduleInspection(pid INTEGER PRIMARY KEY,_id TEXT, scheduleInspectionData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS workflowSync(pid INTEGER PRIMARY KEY,_id TEXT, rfiId TEXT, workflowSyncData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS receiver(pid INTEGER PRIMARY KEY,_id TEXT,receiverData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS project(pid INTEGER PRIMARY KEY,_id TEXT,projectData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS contact(pid INTEGER PRIMARY KEY,_id TEXT,contactData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS client(pid INTEGER PRIMARY KEY,_id TEXT,clientData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS company(pid INTEGER PRIMARY KEY,_id TEXT,companyData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS user(pid INTEGER PRIMARY KEY,_id TEXT,userData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS qrCodeUsed(pid INTEGER PRIMARY KEY,_id TEXT, qrCodeUsedData TEXT, isSynced BOOLEAN, createdOn TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL)");
  
          },
          function (error) {
            console.log("Transaction error: " + error.message);
          },
          function () {
            console.log("Create DB transaction completed successfully");
          }
        );
      }, 1000);

     
    }
  }

  companyHandler = {
    insertCompanyDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (contact) {
          tx.executeSql(
            "insert into company(_id, companyData, isSynced) values(?,?,?)",
            [contact._id, JSON.stringify(contact), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedCompany: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM company WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getCompany: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM company", [], function (data, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    }
  }

  userHandler = {
    insertUserDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (contact) {
          tx.executeSql(
            "insert into user(_id, userData, isSynced) values(?,?,?)",
            [contact._id, JSON.stringify(contact), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedUser: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM user WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getUser: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM user", [], function (data, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    }, getUserById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM user where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }
  contactHandler = {
    insertContactDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (contact) {
          tx.executeSql(
            "insert into contact(_id, contactData, isSynced) values(?,?,?)",
            [contact._id, JSON.stringify(contact), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedContacts: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM contact WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    selectAllContact: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM contact", [], function (data, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    }
  }

  rfiHandler = {
    insertSelectedRFI: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into rfiInspection(_id, inspectionData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), true],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getSelectedRFI: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM rfiInspection", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedRFI: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('UPDATE rfiInspection SET inspectionData=?, isSynced=? where _id=?', [JSON.stringify(data), false, data._id],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedRFI: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM rfiInspection WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getRFIById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM rfiInspection where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

  }

  syncRFIHandler = {

    getSelectedRFIToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM rfiInspection WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllRFIToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM rfiInspection WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedRFISyncStatus: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE rfiInspection SET isSynced=? where _id=?', [true, data._id],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedRFIUnSyncStatus: function (self, id, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE rfiInspection SET isSynced=? where _id=?', [false, id],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }

  equipmentHandler = {
    insertEquipmentDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (equipment) {
          tx.executeSql(
            "insert into equipment(_id, equipmentData, isSynced) values(?,?,?)",
            [equipment._id, JSON.stringify(equipment), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    insertOfflineEquipmentDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into equipment(_id, equipmentData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedEquipment: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM equipment WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getExistingEquipmentByCategoryID: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM equipment", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    getExistingEquipmentToPatch: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM equipment", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    getExistingEquipmentToPatchNew: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM equipment ORDER BY pid DESC LIMIT 1", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    getEquipmentById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM equipment where _id=? or pid=?', [data._id, data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    updateEquipmentCompanyById: function (self, data, resResult) {
      db.transaction(function (tx) {
        if (data._id !== undefined && data._id !== null && data._id !== '') {
          tx.executeSql('UPDATE equipment SET isSynced=?, equipmentData=? where _id=?', [false, JSON.stringify(data), data._id],
            function (transaction, result) {
              resResult({ self: self, data: 'Updated Successfully', status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        } else {
          tx.executeSql('UPDATE equipment SET isSynced=?, equipmentData=? where pid=?', [false, JSON.stringify(data), data.pid],
            function (transaction, result) {
              resResult({ self: self, data: 'Updated Successfully', status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        }
      },
        function (error) { },
        function () { }
      );
    },


    getSelectedEquipmentToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM equipment WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllEquipmentToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM equipment WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedEquipmentSyncStatus: function (self, data, data1, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE equipment SET isSynced=?, _id=?, equipmentData=? where pid=?', [true, self.data._id, JSON.stringify(data1), data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

  }

  workflowHandler = {
    insertWorkflowDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (workflow) {
          tx.executeSql(
            "insert into workflow(_id, inspectionId, workflowData, isSynced) values(?,?,?,?)",
            [workflow._id, workflow.inspectionId, JSON.stringify(workflow), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedWorkflow: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM workflow WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getWorkflowByRFIId: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * from workflow WHERE inspectionId=?', [data],
          function (transaction, result) {
            resResult({ self: self, data: result, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) {
          console.log(error);
        })
    },

    updateWorkflow: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('UPDATE workflow SET workflowData=?, isSynced=? where _id=?', [JSON.stringify(data), false, data._id],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    }

  }

  qrCodehandler = {
    insertQrcodeDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (qrCode) {
          tx.executeSql(
            "insert into qrCode(_id, qrCodeData, isSynced) values(?,?,?)",
            [qrCode._id, JSON.stringify(qrCode), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedQrcode: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM qrCode WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getAllQrCode: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM qrCode", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getqrCodeById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM qrCode where _id=?', [data],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    }
  }

  rfiReporthandler = {
    insertrfiReportDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (rfiReport) {
          tx.executeSql(
            "insert into rfiReport(_id,rfiID, rfiReportData, isSynced) values(?,?,?,?)",
            [rfiReport._id, rfiReport.scheduleIspectionId.ref, JSON.stringify(rfiReport), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    insertscheduleReportDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into rfiReport(_id,rfiID, rfiReportData, isSynced) values(?,?,?,?)",
          [data._id, data.scheduleIspectionId.ref, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedRfiReport: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM rfiReport WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },


    getAllReportData: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM rfiReport", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    }, getAllReportDataFormOfflieByID: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM rfiReport", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    getRFIReportDataByRFIId: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM rfiReport where rfiID=?', [data],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getRFIReportDataByRFIIdSync: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM rfiReport where rfiID=? and isSynced=?', [data,false],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    updateRFIReportById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('UPDATE rfiReport SET rfiReportData=?, isSynced=? where rfiID=?', [JSON.stringify(data), false, data.scheduleIspectionId.ref],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getSelectedRFIReportToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM rfiReport WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    getAllRFIReportToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM rfiReport WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedRFIReportSyncStatus: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE rfiReport SET isSynced=? where pid=?', [true, data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    updateSelectedRFIReportUnSyncStatus: function (self, rfiId, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE rfiReport SET isSynced=? where rfiID=?', [true, rfiId],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },


  }

  locationhandler = {
    insertLocationDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (location) {
          tx.executeSql(
            "insert into location(_id, locationData, isSynced) values(?,?,?)",
            [location._id, JSON.stringify(location), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    getLocationById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM location where _id=? or pid=?', [data._id, data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getSelectedLocationToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM location WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllLocationToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM location WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedLocations: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM location WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    insertOfflineLocationDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into location(_id, locationData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    updateSelectedLocationSyncStatus: function (self, data, data1, resResult) {
      db.transaction(function (tx) {
        if (data1.isData === true) {
          tx.executeSql('UPDATE location SET isSynced=?, _id=?, locationData=? where pid=?', [true, self.data._id, JSON.stringify(data1), data.pid],
            function (transaction, result) {
              resResult({ self: self, data: 'Updated Successfully', status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        } else {
          resResult({ self: self, data: 'Updated Successfully', status: true });
        }
      },
        function (error) { },
        function () { }
      );
    },
    getLocations: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM location", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
  }

  manufacturehandler = {
    insertManufatureDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (manufacture) {
          tx.executeSql(
            "insert into manufacture(_id, manufactureData, isSynced) values(?,?,?)",
            [manufacture._id, JSON.stringify(manufacture), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedManufacture: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM manufacture WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getManufacture: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM manufacture", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getSelectedManufactureToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM manufacture WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllManufactureToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM manufacture WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    updateSelectedManufactureSyncStatus: function (self, data, data1, resResult) {

      db.transaction(function (tx) {
        if (data1.isData === true) {

          tx.executeSql('UPDATE manufacture SET isSynced=?, _id=?, manufactureData=? where pid=?', [true, self.data._id, JSON.stringify(data1), data.pid],
            function (transaction, result) {
              resResult({ self: self, data: 'Updated Successfully', status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        } else {
          resResult({ self: self, data: 'Updated Successfully', status: true });
        }
      },
        function (error) { },
        function () { }
      );
    },
    getManufactureById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM manufacture where _id=? or pid=?', [data._id, data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    insertOfflineManufactureDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into manufacture(_id, manufactureData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }

  typehandler = {
    insertTypeDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (type) {
          tx.executeSql(
            "insert into type(_id, typeData, isSynced) values(?,?,?)",
            [type._id, JSON.stringify(type), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedTypes: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM type WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getTypes: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM type", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    insertOfflineTypeDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into type(_id, typeData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getTypeById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM type where _id=? or pid=?', [data._id, data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getSelectedTypeToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM type WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllTypeToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM type WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    updateSelectedTypeSyncStatus: function (self, data, data1, resResult) {

      db.transaction(function (tx) {
        if (data1.isData === true) {
          tx.executeSql('UPDATE type SET isSynced=?, _id=?, typeData=? where pid=?', [true, self.data._id, JSON.stringify(data1), data.pid],
            function (transaction, result) {
              resResult({ self: self, data: 'Updated Successfully', status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        } else {
          resResult({ self: self, data: 'Updated Successfully', status: true });
        }
      },
        function (error) { },
        function () { }
      );
    },
  }

  equipmentCompanyhandler = {
    insertEquipmentCompanyDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (equipmentCompany) {
          tx.executeSql(
            "insert into equipmentCompany(_id, equipmentCompanyData, isSynced) values(?,?,?)",
            [equipmentCompany._id, JSON.stringify(equipmentCompany), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedequipmentCompany: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM equipmentCompany WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getEquipmentCompany: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM equipmentCompany", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getEquipmentCompanyDataById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM equipmentCompany where _id=?', [data],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }


  checklisthandler = {
    insertChecklistDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (checklist) {
          tx.executeSql(
            "insert into checklist(_id, checklistData, isSynced) values(?,?,?)",
            [checklist._id, JSON.stringify(checklist), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    getChecklists: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM checklist", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedChecklist: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM checklist WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getChecklistById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM checklist where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }

  scheduleInspectionhandler = {
    insertScheduleInspectionDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (scheduleInspection) {
          tx.executeSql(
            "insert into scheduleInspection(_id, scheduleInspectionData, isSynced) values(?,?,?)",
            [scheduleInspection._id, JSON.stringify(scheduleInspection), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    getScheduleInspection: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM scheduleInspection", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedScheduleInspection: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM scheduleInspection WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getScheduleInspectionById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM scheduleInspection where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

  }

  attachmentHandler = {
    insertAttachments: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (attachment) {
          tx.executeSql(
            "insert into attachment(_id, refID,module, attachmentData, isSynced) values(?,?,?,?,?)",
            [attachment._id, attachment.refID, attachment.module, JSON.stringify(attachment), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );


    },
    getAttachments: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM attachment where module='RFI'", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },

    insertAttachmentsInOffline: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into attachment( refID,module, attachmentData, isSynced) values (?,?,?,?)",
          [data.refID, data.module, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );

    },

    updateAttachmentsInOffline: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('UPDATE attachment SET  attachmentData=?, isSynced=? where pid=?',
          [JSON.stringify(data), false, data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },


    deleteSyncedAttachment: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM attachment WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getAttachmentByRefID: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM attachment where refID=? and module=?', [data.refID, data.module],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getSignaturetoSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM attachment WHERE isSynced = 'false' ORDER BY rowid ASC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllSignaturetoSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM attachment WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAttachmentByRefIDUnSync: function (self, refID, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM attachment where refID=? and isSynced=?', [refID, false],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    updateSignatureStatus: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE attachment SET isSynced=? where pid=?', [true, data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    updateSignatureUnSyncStatus: function (self, id, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE attachment SET isSynced=? where refID=?', [false, id],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

  }

  workflowSyncHandler = {
    insertWorkFlowHandler: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into workflowSync( rfiId, workflowSyncData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );


    },

    getWorkflowSynctoSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM workflowSync WHERE isSynced = 'false' ORDER BY rowid ASC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllWorkflowSynctoSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM workflowSync WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getWorkflowByRefIDUnSync: function (self, rfiID, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM workflowSync where rfiId=? and isSynced=?', [rfiID,false],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    updateSelectedWorkflowSyncStatus: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE workflowSync SET isSynced=? where pid=?', [true, data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }

  offlineDataDeleteHandler = {
    deleteDatabase: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("DELETE FROM rfiInspection");
        tx.executeSql("DELETE FROM equipment");
        tx.executeSql("DELETE FROM workflow");
        tx.executeSql("DELETE FROM attachment");
        tx.executeSql("DELETE FROM qrCode");
        tx.executeSql("DELETE FROM rfiReport");
        tx.executeSql("DELETE FROM location");
        tx.executeSql("DELETE FROM manufacture");
        tx.executeSql("DELETE FROM type");
        tx.executeSql("DELETE FROM equipmentCompany");
        tx.executeSql("DELETE FROM checklist");
        tx.executeSql("DELETE FROM scheduleInspection");
        tx.executeSql("DELETE FROM workflowSync");
        tx.executeSql("DELETE FROM receiver");
        tx.executeSql("DELETE FROM project");
        tx.executeSql("DELETE FROM contact");
        tx.executeSql("DELETE FROM client");
        tx.executeSql("DELETE FROM company");
        tx.executeSql("DELETE FROM user");
        tx.executeSql("DELETE FROM qrCodeUsed");
      },
        function (error) {
          console.log("Transaction error: " + error.message);
        },
        function (transaction, result) {
          resResult({ self: self, data: 'Deleted successfully', status: true });
          console.log("Deleted successfully");
        },
      );
    }
  }

  receiverHandler = {
    insertReceiverDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (receiver) {
          tx.executeSql(
            "insert into receiver(_id, receiverData, isSynced) values(?,?,?)",
            [receiver._id, JSON.stringify(receiver), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    updateSelectedReceiverRecord: function (self, data, data1, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('UPDATE receiver SET isSynced=?, _id=?, receiverData=? where pid=?', [true, self.data._id, JSON.stringify(data1), data.pid],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedReceiver: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM receiver WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    insertOfflineReceiverDetails: function (self, data, resResult) {
      db.transaction(function (tx) {

        tx.executeSql(
          "insert into receiver(_id, receiverData, isSynced) values(?,?,?)",
          [data._id, JSON.stringify(data), false],
          function (tx, results) {
            resResult({ self: self, data: results, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getSelectedReceiverToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM receiver WHERE isSynced = 'false' ORDER BY rowid DESC LIMIT 1";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getAllReceiverToSend: function (self, resResult) {
      db.transaction(function (tx) {
        var query = "SELECT * FROM receiver WHERE isSynced = 'false'";
        tx.executeSql(query, [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getReceiver: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM receiver", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getReceiverById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM receiver where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getReceiverByIdOrPid: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM receiver where _id=? or pid=?', [data._id, data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }

  projectHandler = {
    insertProjectDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (project) {
          tx.executeSql(
            "insert into project(_id, projectData, isSynced) values(?,?,?)",
            [project._id, JSON.stringify(project), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },
    deleteSyncedProject: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM project WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },

    getProject: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM project", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    },
    getClientByProjectId: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM project where _id=?', [data._id],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
  }


  clientHandler = {
    insertClientDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (type) {
          tx.executeSql(
            "insert into client(_id, clientData, isSynced) values(?,?,?)",
            [type._id, JSON.stringify(type), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedClient: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM client WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },
    getClients: function (self, resResult) {
      db.transaction(function (tx) {
        tx.executeSql("SELECT * FROM client", [], function (sqlTransaction, sqlResultSet) {
          var rows = sqlResultSet.rows;
          if (rows.length > 0) {
            resResult({ self: self, data: rows });
          } else {
            resResult({ self: self, data: '' });
          }
        }, function (sqlTransaction, sqlError) {
          resResult({ self: self, data: sqlError.message });
        });
      },
        function (error) { },
        function () { }
      );
    }
  }
  qrCodeUsedhandler = {
    insertQrCodeUsedDetails: function (self, data, resResult) {
      db.transaction(function (tx) {
        data.forEach(function (qrCode) {
          tx.executeSql(
            "insert into qrCodeUsed(_id, qrCodeUsedData, isSynced) values(?,?,?)",
            [qrCode._id, JSON.stringify(qrCode), true],
            function (tx, results) {
              resResult({ self: self, data: results, status: true });
            },
            function (tx, error) {
              console.log("SQL Error: " + error.message);
            }
          );
        });
      },
        function (error) { },
        function () { }
      );
    },

    deleteSyncedQrCodeUsed: function (self, resResult) {
      db.transaction(function (tx) {

        tx.executeSql('DELETE FROM qrCodeUsed WHERE isSynced=?', [true],
          function (transaction, result) {
            resResult({ self: self, data: 'Updated Successfully', status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    },


    getqrCodeUsedById: function (self, data, resResult) {
      db.transaction(function (tx) {
        tx.executeSql('SELECT * FROM qrCodeUsed where _id=?', [data],
          function (transaction, result) {
            var rows = result.rows;
            resResult({ self: self, data: rows, status: true });
          },
          function (tx, error) {
            console.log("SQL Error: " + error.message);
          }
        );
      },
        function (error) { },
        function () { }
      );
    }
  }
}
