import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { QrcodeService } from '../../qrcode/qrcode.service';
import { EquipmentService } from '../../equipment/equipment.service';
import { ScheduleInspectionReportService } from '../schedule-inspection-report.service';
import { EquipmentFormComponent } from '../../equipment/equipment-form/equipment-form.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { RfiInspectionService } from '../../../master/rfi-inspection/rfi-inspection.service';
import { SelectEquipmentComponent } from '../../../master/scheduleInspection/select-equipment/select-equipment.component';
import * as moment from 'moment';
import SignaturePad from 'signature_pad';
import { FileService } from '../../../services/files/file.service';
import * as _ from 'underscore';
import { CordovaService } from '../../../cordova.service';
import { environment } from '../../../../environments/environment';
import { OfflineStorageService } from '../../../mobile-native-interface/offline-storage.service.service';
import { ReceiverFormComponent } from '../../receiver/receiver-form/receiver-form.component';
import { SelectReceiverComponent } from '../../scheduleInspection/select-receiver/select-receiver.component';
import { ReceiverService } from '../../receiver/receiver.service';
import { ScheduleInspectionReportEmailFormComponent } from '../schedule-inspection-report-email-form/schedule-inspection-report-email-form.component';
import { ReceiverDetailDialogComponent } from '../../../general/receiver-detail-dialog/receiver-detail-dialog.component';
import { NotesPopupComponent } from '../../../general/notes-popup/notes-popup.component';
import { ConfirmDialogComponent } from 'src/app/admin/confirm-dialog/confirm-dialog.component';

declare var cordova: any;


@Component({
  selector: 'app-schedule-inspection-report',
  templateUrl: './schedule-inspection-report.component.html',
  styleUrls: ['./schedule-inspection-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class ScheduleInspectionReportComponent implements OnInit {

  qrStickers = []; QRStickers = [];
  equNo: any;
  equLocation: any;
  equManufacturer: any
  equCapacity: any;
  equType: any;
  equModel: any;
  isShow = true;
  inputData: any;
  user: any;
  equID: any;
  deficiencycorrectiveActionDetails = [];
  reportNo = new FormControl('');
  inspectionDate = new FormControl('');
  inspectionTime = new FormControl('', [Validators.required]);
  area = new FormControl('');
  orgCode = new FormControl('');
  newStickerNumber = new FormControl('', [Validators.required]);
  newStickerNo = new FormControl('', [Validators.required]);
  deficiencies = new FormControl('');
  correctiveActionTaken = new FormControl('');
  technicalReviewerName = new FormControl('', [Validators.required]);
  technicalReviewerBatchNo = new FormControl('', [Validators.required]);
  technicalReviewerEmail = new FormControl('', [Validators.email, Validators.required]);
  technicalReviewerMobile = new FormControl('');
  inspectionType = new FormControl('');
  loadTest = new FormControl('');
  type = new FormControl('', [Validators.required]);
  result = new FormControl('');
  noOfDays = new FormControl('');
  proponent = new FormControl('', [Validators.required]);
  expiryDate = new FormControl('')
  reportID: any;
  isSave = false;
  isUpdate = false;
  isReceiver = false;
  status = '';
  reportStatus: boolean = false;
  allQRCodes: any;
  barcodeImg = 'assets/images/icons/barcode.png';
  isMobileApp = environment.mobileApp;
  reportEmail = [];
  existingData = [];
  dataSource: any;

  displayedColumns = [
    'name',
    'emailID',
    'action'
  ];

  canvas1: any;
  canvas2: any;
  canvas3: any;

  wrapper1: any;
  wrapper2: any;
  wrapper3: any;

  signaturePad1: any;
  signaturePad2: any;
  signaturePad3: any;

  signaturePad1Base64: any;
  signaturePad2Base64: any;
  signaturePad3Base64: any;

  inspector: any;
  permission: any;
  isWrite = false;
  isLoadTest = false;

  isPass = false;
  inspections: string[] = ['NA', 'P.I. - Periodic inspection', 'Re.I. - Reinspection', 'I.I. - Initial inspection'];
  loadTests: string[] = ['NA', 'M - Manufacturer', 'W - Witness', 'P - Perform'];
  types: string[] = ['Inspected', 'Witnessed by 3rd Party'];
  scannedSticker = '';

  isOffline = false;
  updatedOldStickerNo: any;
  receiverId: any;
  equipmentId: any;
  isSpecialButton = false;

  constructor(
    private qrcodeService: QrcodeService, private dialog: MatDialog,
    private equipmentService: EquipmentService,
    private scheduleInspectionReportService: ScheduleInspectionReportService,
    private route: ActivatedRoute,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private dialogRef: MatDialogRef<ScheduleInspectionReportComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private rfiInspectionService: RfiInspectionService,
    private fileService: FileService,
    private cordovaService: CordovaService,
    private offlineStorageService: OfflineStorageService, private ngZone: NgZone, private receiverService: ReceiverService,
  ) { }

  ngOnInit() {
    this.checkOffline();
    this.isSpecialButton = false;
    if (this.data.data.inspector) {
      if (this.data.data.inspector.ref) {
        this.inspector = this.data.data.inspector.ref;
      }
    }
    if (this.data.data.checkListDetails && this.data.data.checkListDetails.length > 0) {
      let data = this.data.data.checkListDetails;
      for (let i = 0; i < data.length; i++) {
        let testString = data[i].checklist.name.toLowerCase();
        if (testString.includes('load test')) {
          this.isLoadTest = true;
        }
      }
    }
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    if (this.permission && this.permission.inspections && this.permission.inspections.rfi && this.permission.inspections.rfi.write) {
      this.isWrite = true;
    }
    if (this.permission && this.permission.project && this.permission.project.stickerRevoke && this.permission.project.stickerRevoke.write) {
      this.status = this.data.data.activatedStatusName;
      if (this.status === 'Review completed. Waiting for OTP approval' || this.status === 'Approved') {
        this.isSpecialButton = true;
      }
    }

    if (this.data.data) {
      if (this.isOffline) {
        let off_data = {
          _id: this.data.data._id
        };
        let rfiData = [];
        this.offlineStorageService.rfiHandler.getRFIById(this, off_data, function (res) {
          let _this = res.self;
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              rfiData.push(JSON.parse(res.data.item(i).inspectionData));
            }
          }

          if (rfiData.length > 0) {
            if (rfiData[0].equipment) {
              let data1 = {
                _id: rfiData[0].equipment.ref
              }
              _this.offlineStorageService.equipmentHandler.getEquipmentById(_this, data1, function (res) {
                let _this = res.self, temp = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    temp.push(JSON.parse(res.data.item(i).equipmentData));
                  }
                  if (temp.length > 0) {
                    _this.updatedOldStickerNo = temp[0].updatedPreviousStickerNumber ?
                      temp[0].updatedPreviousStickerNumber : temp[0].manualSticker;
                  }
                }
              });
            }
          }
        });
      }
      else {
        let data1 = {
          _id: this.data.data._id
        }
        this.rfiInspectionService.getReportDataById(data1).subscribe(result => {
          if (result.length > 0) {
            if (result[0].equipment) {
              this.equipmentId = result[0].equipment.ref;
            }
            if (this.equipmentId !== undefined && this.equipmentId !== null && this.equipmentId !== '') {
              var data = {
                equipmentID: this.equipmentId
              }
              this.equipmentService.getRecordById(data).subscribe(result => {
                let data = result;
                if (data.length > 0) {
                  this.updatedOldStickerNo = data[0].updatedPreviousStickerNumber ? data[0].updatedPreviousStickerNumber : data[0].manualSticker;
                }
              });
            }
          }


        });

      }
    }

    this.getStickerNo();
    this.patchValue();
    this.patchEquipmentValue();
    this.canvas();
    this.patchCanvas1();
    this.patchCanvas2();
    this.patchCanvas3();
    this.getReportEmail();
    this.getReportStatus();
    this.PreviousStickerpatch();

    // if (this.data.data.equipment && this.data.data.equipment.ref) {
    //   this.PreviousStickerpatch();
    // }
  }
  PreviousStickerpatch() {
    if (this.data.data) {
      if (this.isOffline) {
        let off_data = {
          _id: this.data.data._id
        };
        let rfiData = [];
        this.offlineStorageService.rfiHandler.getRFIById(this, off_data, function (res) {
          let _this = res.self;
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              rfiData.push(JSON.parse(res.data.item(i).inspectionData));
            }
          }

          if (rfiData.length > 0) {
            if (rfiData[0].equipment) {
              let data1 = {
                _id: rfiData[0].equipment.ref
              }
              _this.offlineStorageService.equipmentHandler.getEquipmentById(_this, data1, function (res) {
                let _this = res.self, temp = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    temp.push(JSON.parse(res.data.item(i).equipmentData));
                  }
                  if (temp.length > 0) {
                    _this.updatedOldStickerNo = temp[0].updatedPreviousStickerNumber ?
                      temp[0].updatedPreviousStickerNumber : temp[0].manualSticker;
                  }
                }
              });
            }
          }
        });
      }
      else {
        let data1 = {
          _id: this.data.data._id
        }
        this.rfiInspectionService.getReportDataById(data1).subscribe(result => {
          if (result.length > 0) {
            if (result[0].equipment) {
              this.equipmentId = result[0].equipment.ref;
            }
            if (this.equipmentId !== undefined && this.equipmentId !== null && this.equipmentId !== '') {
              var data = {
                equipmentID: this.equipmentId
              }
              this.equipmentService.getRecordById(data).subscribe(result => {
                let data = result;
                if (data.length > 0) {
                  this.updatedOldStickerNo = data[0].updatedPreviousStickerNumber ? data[0].updatedPreviousStickerNumber : data[0].manualSticker;
                }
              });
            }
          }


        });

      }
    }

  }
  getReportStatus() {
    let state = this.data.data.activatedStatusName;
    if ((state == "Schedule accepted" || state == "Inspection completed. Send for review")) {
      this.reportStatus = true;
    }
  }

  getReportEmail() {
    if (this.data && this.data.data && this.data.data._id) {

      if (this.isOffline) {
        let rfiData = [], rfiReportData = [];
        let data = {
          _id: this.data.data._id
        };

        this.offlineStorageService.rfiHandler.getRFIById(this, data, function (res) {
          let _this = res.self;
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              rfiData.push(JSON.parse(res.data.item(i).inspectionData));
            }
          }

          _this.offlineStorageService.rfiReporthandler.getAllReportData(_this, function (res1) {
            if (res1 && res1.data && res1.data.length > 0) {
              for (let i = 0; i < res1.data.length; i++) {
                rfiReportData.push(JSON.parse(res1.data.item(i).rfiReportData));
              }
            }
          });

          if (rfiReportData.length > 0) {
            rfiReportData.forEach(element => {
              if (element.scheduleIspectionId && (element.scheduleIspectionId.ref == rfiData[0]._id)) {
                _this.existingData = element.reportEmailDetails;
              }
            });

          }
          _this.dataSource = new MatTableDataSource(_this.existingData);
        });
      }
      else {
        let data = {
          srId: this.data.data._id
        };

        this.scheduleInspectionReportService.getScheduleReport(data).subscribe(result => {
          if (result && result.length > 0) {
            if (result[0].reportEmailDetails) {
              this.existingData = result[0].reportEmailDetails;
            }
          }
          this.dataSource = new MatTableDataSource(this.existingData);
        });
      }
    }
  }

  // openScanner() {
  //   cordova.plugins.barcodeScanner.scan(function (result) {
  //     console.log(result);
  //     if (result.text !== undefined && result.text !== null && result.text !== "") {
  //       this.checkNumber(result.text);
  //     }
  //   });
  // }

  checkOffline() {
    let value = localStorage.getItem('isOffline');
    if (value === 'true') {
      this.isOffline = true;
    } else {
      this.isOffline = false;
    }
    console.log('IsOffline :' + this.isOffline);
  }

  public openScanner(): void {
    this.promiseScan().then(result => {
      this.scannedSticker = result;
      // this.checkNumber();

    }).catch((ex) => {
      console.log(ex);
    });
  }

  public promiseScan(): any {
    return new Promise((resolve, reject) => {
      cordova.plugins.barcodeScanner.scan(
        (result) => {
          return resolve(result.text);
        },
        (error) => {
          return reject('ERROR');
        }
      );
    });
  }

  // checkNumber() {
  //   console.log('Second function');
  //   console.log(this.scannedSticker);
  //   if (this.scannedSticker) {
  //     var QR = [];
  //     QR = _.filter(this.allQRCodes, ele => { return ele.qrScan.encryptVal === this.scannedSticker });
  //     if (QR.length > 0) {
  //       var subQR = [];
  //       subQR = _.filter(this.qrStickers, ele => { return ele.qrScan.encryptVal === this.scannedSticker });
  //       if (subQR.length > 0) {
  //         if (subQR[0].isUsed) {
  //           this.snackBar.open('Sticker number is used for this inspector', '', { duration: 2000, verticalPosition: "bottom" });
  //         } else {
  //           this.newStickerNo.setValue(subQR[0].qrCode);
  //           this.newStickerNumber.setValue(subQR[0]._id);
  //           this.updateResult(subQR[0].type);
  //         }
  //       } else {
  //         this.snackBar.open('Sticker number does not exist for this inspector', '', { duration: 2000, verticalPosition: "bottom" });
  //       }
  //     } else {
  //       this.snackBar.open('Sticker number does not exist', '', { duration: 2000, verticalPosition: "bottom" });
  //     }
  //   }
  // }

  updateExpiryByDate() {
    if (this.noOfDays.value) {
      this.updateExpiry(this.noOfDays.value);
    }
  }

  updateExpiry(data) {
    var num;
    var date = this.inspectionDate.value;
    var value = data;
    if (value === '3 months') {
      num = 3
    } else if (value === '6 months') {
      num = 6
    } else if (value === '12 months') {
      num = 12
    }
    if (num > 0 && num != null && num != undefined && num != '') {
      var tempDate = moment(date).subtract(1, 'days')
      var expiryDate = moment(tempDate).add(num, 'M')
      // expiryDate = moment(expiryDate).subtract(1, 'days')
      var finExpiryDate = moment(expiryDate).format('DD/MM/YYYY');
      this.expiryDate.setValue(finExpiryDate);
    } else {
      this.expiryDate.setValue(null);
    }

  }

  addRow(deficiencycorrectiveActionDetails, index) {

    this.deficiencycorrectiveActionDetails.push({
      deficiencies: "",
      correctiveActionTaken: "",
      "addNew": false,
      "remove": false,
      "isActive": true,
    });
    this.deficiencycorrectiveActionDetails.forEach(function addData(row, index) {
      row.addNew = false;
      row.remove = true;
    });
    var last_element = this.deficiencycorrectiveActionDetails[this.deficiencycorrectiveActionDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;


  };

  removeRow(deficiencycorrectiveActionDetails, index) {
    this.deficiencycorrectiveActionDetails[index].isActive = false;
    this.deficiencycorrectiveActionDetails.splice(index, 1);
    var last_element = this.deficiencycorrectiveActionDetails[this.deficiencycorrectiveActionDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (this.deficiencycorrectiveActionDetails.length == 1) {
      last_element.remove = false;
    }
  };
  canvas() {
    this.canvas1 = document.getElementById('signaturePad1');
    this.wrapper1 = this.canvas1.querySelector("canvas");

    this.signaturePad1 = new SignaturePad(this.canvas1, {
      penColor: "black"
    });

    this.canvas2 = document.getElementById('signaturePad2');
    this.wrapper2 = this.canvas2.querySelector("canvas");

    this.signaturePad2 = new SignaturePad(this.canvas2, {
      penColor: "black"
    });

    this.canvas3 = document.getElementById('signaturePad3');
    this.wrapper3 = this.canvas3.querySelector("canvas");
    this.signaturePad3 = new SignaturePad(this.canvas3, {
      penColor: "black"
    });


    this.signaturePad1.on();
    this.signaturePad2.on();
    this.signaturePad3.on();



  }

  patchCanvas1() {
    var data = {
      refID: this.data.data._id,
      module: 'Inspector signature'
    }
    if (this.isOffline) {
      this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data, function (res) {

        let _this = res.self;
        if (res.data.length > 0) {
          var resultData = JSON.parse(res.data.item(0).attachmentData);
          _this.signaturePad1._isEmpty = false;
          _this.canvas1 = document.getElementById('signaturePad1');
          _this.wrapper1 = _this.canvas1.querySelector("canvas");
          var ctx = _this.canvas1.getContext("2d");
          var image = new Image();
          image.src = resultData.content;
          image.onload = function () {
            ctx.drawImage(image, 0, 0);
          };
        }

      })
    } else {
      this.fileService.getRecord(data).subscribe(result => {
        if (result.length > 0) {
          const last = _.last(result);
          this.signaturePad1._isEmpty = false;
          this.canvas1 = document.getElementById('signaturePad1');
          this.wrapper1 = this.canvas1.querySelector("canvas");
          var ctx = this.canvas1.getContext("2d");
          var image = new Image();
          image.src = last.content;
          image.onload = function () {
            ctx.drawImage(image, 0, 0);
          };
        }
      })
    }
  }

  patchCanvas2() {
    var data = {
      refID: this.data.data._id,
      module: 'Reviewer signature'
    }
    if (this.isOffline) {
      this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data, function (res) {
        let _this = res.self;
        if (res.data.length > 0) {
          var resultData = JSON.parse(res.data.item(0).attachmentData);
          _this.signaturePad2._isEmpty = false;
          _this.canvas2 = document.getElementById('signaturePad2');
          _this.wrapper2 = _this.canvas2.querySelector("canvas");
          var ctx = _this.canvas2.getContext("2d");
          var image = new Image();
          image.src = resultData.content;
          image.onload = function () {
            ctx.drawImage(image, 0, 0);
          };
        }
      })
    } else {
      this.fileService.getRecord(data).subscribe(results => {
        if (results.length > 0) {
          const last = _.last(results);
          this.signaturePad2._isEmpty = false;
          this.canvas2 = document.getElementById('signaturePad2');
          this.wrapper2 = this.canvas2.querySelector("canvas");
          var ctxr = this.canvas2.getContext("2d");
          var imager = new Image();
          imager.src = last.content;
          imager.onload = function () {
            ctxr.drawImage(imager, 0, 0);
          };
        }
      })
    }
  }

  patchCanvas3() {
    var data = {
      refID: this.data.data._id,
      module: 'Load test signature'
    }
    if (this.isOffline) {
      this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data, function (res) {
        let _this = res.self;
        if (res.data.length > 0) {
          var resultData = JSON.parse(res.data.item(0).attachmentData);
          _this.signaturePad3._isEmpty = false;
          _this.canvas3 = document.getElementById('signaturePad3');
          _this.wrapper3 = _this.canvas3.querySelector("canvas");
          var ctx = _this.canvas3.getContext("2d");
          var image = new Image();
          image.src = resultData.content;
          image.onload = function () {
            ctx.drawImage(image, 0, 0);
          };
        }
      })
    } else {
      this.fileService.getRecord(data).subscribe(results => {
        if (results.length > 0) {
          const last = _.last(results);
          this.signaturePad3._isEmpty = false;
          this.canvas3 = document.getElementById('signaturePad3');
          this.wrapper3 = this.canvas3.querySelector("canvas");
          var ctxr = this.canvas3.getContext("2d");
          var imager = new Image();
          imager.src = last.content;
          imager.onload = function () {
            ctxr.drawImage(imager, 0, 0);
          };
        }
      })
    }
  }

  clearSignature(value) {
    if (value === 1) {
      this.signaturePad1.clear();
    } else if (value === 2) {
      this.signaturePad2.clear();
    }
    else if (value === 3) {
      this.signaturePad3.clear();
    }
  }

  enableSave() {
    this.status = this.data.data.activatedStatusName;
    if (this.status === 'Schedule accepted' || this.status === 'Inspection completed. Send for review') {
      this.isReceiver = true;
    } else {
      this.isSave = false;
      this.isUpdate = false;
      this.isReceiver = false;
      this.reportStatus = false;
    }
  }

  patchValue() {
    this.inspectionType.setValue(this.data.data.inspectionType);
    this.loadTest.setValue(this.data.data.loadTest);
    if (this.isOffline) {
      this.patchOfflineData();
    } else {
      var data = {
        _id: this.data.data._id
      }
      this.rfiInspectionService.getReportDataByScheduleId(data).subscribe(result => {
        if (result.length > 0) {
          this.isUpdate = true;
          this.isReceiver = true;
          let data = result[0];
          this.bindReceiverDetail(data);
          this.patchValuesFromData(data);
        } else {
          this.isSave = true;
          this.isReceiver = true;
          this.deficiencycorrectiveActionDetails = [{
            deficiencies: "",
            correctiveActionTaken: "",
            "addNew": true,
            "remove": false,
            "isActive": true,
          }]

        }
        this.enableSave();
        this.updateStickerDropDown();

      })
    }
  }

  patchOfflineData() {
    var id = this.data.data._id;
    this.offlineStorageService.rfiReporthandler.getRFIReportDataByRFIId(this, id, function (res) {
      let _this = res.self;
      if (res && res.data && res.data.length > 0) {
        _this.isUpdate = true;
        _this.patchValuesFromData(JSON.parse(res.data.item(0).rfiReportData));
        _this.bindReceiverDetailFromOffline(JSON.parse(res.data.item(0).rfiReportData));
        _this.bindEmailDetailFromOffline(JSON.parse(res.data.item(0).rfiReportData));
      } else {
        _this.isSave = true;
        _this.isReceiver = true;
        _this.deficiencycorrectiveActionDetails = [{
          deficiencies: "",
          correctiveActionTaken: "",
          "addNew": true,
          "remove": false,
          "isActive": true,
        }]
      }

    })
  }
  bindReceiverDetailFromOffline(data) {
    if (this.isOffline == true) {
      if (data.receiverId) {
        var data1 = {
          _id: data.receiverId,
        }
        this.offlineStorageService.receiverHandler.getReceiverById(this, data1, function (res) {
          let _this = res.self;
          var receiverData = [];
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              var datas = JSON.parse(res.data.item(i).receiverData);
              receiverData.push(datas);
            }
            if (receiverData && receiverData.length > 0) {
              _this.technicalReviewerName.setValue(receiverData[0].name);
              _this.technicalReviewerBatchNo.setValue(receiverData[0].badgeNo);
              _this.technicalReviewerEmail.setValue(receiverData[0].emailID);
              _this.technicalReviewerMobile.setValue(receiverData[0].telephone);
            }
            _this.receiverId = receiverData[0]._id;
          }
        });
      }
    }

  }

  bindEmailDetailFromOffline(data) {
    if (this.isOffline == true) {
      if (data.reportEmailDetails) {

        this.existingData = data.reportEmailDetails;

        this.dataSource = new MatTableDataSource(this.existingData);

      }
    }
  }
  bindReceiverDetail(data) {
    var data1 = {
      _id: data.receiverId
    }

    this.receiverService.getRecordsById(data1).subscribe(data => {
      if (data && data.length > 0) {

        this.technicalReviewerName.setValue(data[0].name);
        this.technicalReviewerBatchNo.setValue(data[0].badgeNo);
        this.technicalReviewerEmail.setValue(data[0].emailID);
        this.technicalReviewerMobile.setValue(data[0].telephone);

      }
      this.receiverId = data[0]._id;
    });

  }
  patchValuesFromData(data) {
    this.reportID = data._id;
    this.receiverId = data.receiverId;
    this.reportNo.setValue(data.reportNo);
    // var date = moment(new Date(data.inspectionDate)).format('DD/MM/YYYY');
    this.inspectionDate.setValue(data.inspectionDate);
    this.inspectionTime.setValue(data.inspectionTime);
    this.area.setValue(data.area);
    this.orgCode.setValue(data.orgCode);
    if (data.newStickerNumber && data.newStickerNumber.ref) {
      this.newStickerNumber.setValue(data.newStickerNumber.ref);
    }
    if (data.newStickerNumber && data.newStickerNumber.stickerNumber) {
      this.newStickerNo.setValue(data.newStickerNumber.stickerNumber);
    }
    // this.deficiencies.setValue(data.deficiencies);
    this.result.setValue(data.result);
    this.noOfDays.setValue(data.noOfDays);
    this.proponent.setValue(data.proponent);
    this.updateExpiry(data.noOfDays);
    this.type.setValue(data.type);

    // this.correctiveActionTaken.setValue(data.correctiveActionTaken);
    if (data.deficiencycorrectiveActionDetails != undefined && data.deficiencycorrectiveActionDetails.length > 0) {
      for (var i = 0; i < data.deficiencycorrectiveActionDetails.length; i++) {
        data.deficiencycorrectiveActionDetails[i].addNew = false;
        data.deficiencycorrectiveActionDetails[i].remove = true;
        data.deficiencycorrectiveActionDetails[i].isActive = true;
        this.deficiencycorrectiveActionDetails.push({
          "deficiencies": data.deficiencycorrectiveActionDetails[i].deficiencyDetails.deficiencies,
          "correctiveActionTaken": data.deficiencycorrectiveActionDetails[i].deficiencyDetails.correctiveActionTaken,
        });
      }
      var last_element = this.deficiencycorrectiveActionDetails[this.deficiencycorrectiveActionDetails.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.deficiencycorrectiveActionDetails.length == 1) {
        last_element.remove = false;
      }
    } else {
      this.deficiencycorrectiveActionDetails = [{
        deficiencies: "",
        correctiveActionTaken: "",

        "addNew": true,
        "remove": false,
        "isActive": true,
      }]
    }
    if (data.technicalReviewer) {
      this.technicalReviewerName.setValue(data.technicalReviewer.technicalReviewerName);
      this.technicalReviewerBatchNo.setValue(data.technicalReviewer.technicalReviewerBatchNo);
      this.technicalReviewerEmail.setValue(data.technicalReviewer.technicalReviewerEmail);
      this.technicalReviewerMobile.setValue(data.technicalReviewer.technicalReviewerMobile);
    }
    this.enableSave();
    this.updateStickerDropDown();
    if (data.newStickerNumber && data.newStickerNumber.ref) {
      this.QRStickers.push({ 'qrCode': data.newStickerNumber.stickerNumber, '_id': data.newStickerNumber.ref })
    }

  }

  updateStickerDropDown() {
    var selectedQR = [];
    let value = this.newStickerNumber.value;
    if (value !== undefined && value !== null && value !== '') {
      selectedQR = _.filter(this.QRStickers, ele => { return ele.isUsed === false || ele._id === value });
    } else {
      selectedQR = _.filter(this.QRStickers, ele => { return ele.isUsed === false });
    }
    this.QRStickers = _.sortBy(selectedQR, ['qrCode'], 'asc');
  }

  getStickerNo() {

    if (this.isOffline) {

      this.offlineStorageService.qrCodehandler.getAllQrCode(this, function (res) {
        let _this = res.self;
        var fullData = [];
        var inspData = [];
        let resultData = [];
        let finalData = [];
        // if (res.data.rows.length > 0) {
        //   for (var i = 0; i < res.data.rows.length; i++) {
        //     resultData.push(res.data.rows.item(i));
        //   }
        // }

        if (res && res.data && res.data.length > 0) {
          for (var j = 0; j < res.data.length; j++) {
            resultData.push(res.data.item(j));
          }
        }
        // let data = res.data;
        if (resultData.length > 0) {
          for (var i = 0; i < resultData.length; i++) {
            let subData = JSON.parse(resultData[i].qrCodeData);
            fullData.push(subData);
          }
        }
        _this.bindValue(fullData);
        for (var k = 0; k < resultData.length; k++) {
          let subData = JSON.parse(resultData[k].qrCodeData);
          if (subData.inspector.ref === _this.inspector) {
            inspData.push(subData);
          }
        }
        var id = _this.data.data._id;
        _this.offlineStorageService.rfiReporthandler.getRFIReportDataByRFIId(_this, id, function (res) {
          let _this = res.self;
          if (res && res.data && res.data.length > 0) {
            var reportData = (JSON.parse(res.data.item(0).rfiReportData));
          }

          _this.offlineStorageService.rfiReporthandler.getAllReportDataFormOfflieByID(_this, function (res22) {
            let _this = res22.self;
            let ReportData = [];
            let fullData1 = [];
            if (res22 && res22.data && res22.data.length > 0) {
              for (var j = 0; j < res22.data.length; j++) {
                ReportData.push(res22.data.item(j));
              }
              if (ReportData.length > 0) {
                for (var i = 0; i < ReportData.length; i++) {
                  let subData1 = JSON.parse(ReportData[i].rfiReportData);
                  fullData1.push(subData1);
                }
              }
            }
            if (_this.newStickerNumber.value !== null && _this.newStickerNumber.value !== undefined && _this.newStickerNumber.value !== '') {

              let filteredData = inspData.filter(entry1 => 
                !fullData1.some(entry2 => 
                  entry2.newStickerNumber && entry2.newStickerNumber.stickerNumber && entry1.qrCode === entry2.newStickerNumber.stickerNumber
                )
              );
              // let filteredData = inspData.filter(entry1 => !fullData1.some(entry2 => entry1.qrCode === entry2.newStickerNumber.stickerNumber));

              if (filteredData && filteredData.length > 0) {
                if (reportData) {
                  if (reportData.newStickerNumber && reportData.newStickerNumber.ref) {
                    filteredData.push({ 'qrCode': reportData.newStickerNumber.stickerNumber, '_id': reportData.newStickerNumber.ref })
                  }
                }
                _this.bindValue2(filteredData)
              }

            } else {
              if (fullData1 && fullData1.length > 0) {
                let filteredData;

                filteredData = inspData.filter(entry1 => 
                  !fullData1.some(entry2 => 
                    entry2.newStickerNumber && entry2.newStickerNumber.stickerNumber && entry1.qrCode === entry2.newStickerNumber.stickerNumber
                  )
                );
                // filteredData = inspData.filter(entry1 => !fullData1.some(entry2 => entry1.qrCode === entry2.newStickerNumber.stickerNumber));

                console.log(filteredData);


                if (filteredData && filteredData.length > 0) {
                  _this.bindValue2(filteredData)
                } else {
                  _this.bindValue2(inspData)
                }
              } else {
                _this.bindValue2(inspData)
              }
            }

          });
        });


      })

    } else {
      var data = {
        inspector: this.inspector
      }
      this.qrcodeService.getAllRecordsByInspector(data).subscribe(data => {

        this.qrStickers = data;
        this.QRStickers = data;
        this.updateStickerDropDown();
      });
      // this.qrcodeService.getQRCode().subscribe(result => {
      //   this.updateStickerDropDown();
      //   this.allQRCodes = result;
      // });
    }
  }

  bindValue(data) {
    this.allQRCodes = data;
  }

  bindValue2(data) {
    this.qrStickers = data;
    this.QRStickers = _.sortBy(data, ['qrCode'], 'asc');
  }


  save(
    inspectionDate: String,
    inspectionTime: String,
    area: String,
    orgCode: String,
    newStickerNumber: String,
    newStickerNo: String,
    deficiencies: String,
    result: String,
    noOfDays: String,
    proponent: String,
    correctiveActionTaken: String,
    technicalReviewerName: String,
    technicalReviewerBatchNo: String,
    technicalReviewerEmail: String,
    technicalReviewerMobile: Number,
    inspectionType: String,
    loadTest: String,
    type: String
  ): void {
    if (!inspectionTime || !result || !proponent || !type) {
      return;
    }

    // if (environment.mobileApp) {
    //   if (!newStickerNo) {
    //     return;
    //   } else if (!newStickerNumber) {
    //     return;
    //   }
    // }

    if (result === 'PASS') {
      if (noOfDays === null || noOfDays === 'NA' || noOfDays === '' || noOfDays === undefined) {
        this.snackBar.open('Number of months is required', '', { duration: 2000 });
        return;
      }
    }

    this.inputData = {
      scheduleIspectionId: this.data.data._id,
      inspectionTime: inspectionTime,
      area: area,
      orgCode: orgCode,
      newStickerNumber: newStickerNumber,
      updatedOldStickerNo: this.updatedOldStickerNo,
      deficiencies: deficiencies,
      correctiveActionTaken: correctiveActionTaken,
      technicalReviewerName: technicalReviewerName,
      technicalReviewerBatchNo: technicalReviewerBatchNo,
      technicalReviewerEmail: technicalReviewerEmail,
      technicalReviewerMobile: technicalReviewerMobile,
      reportEmailDetails: this.existingData,
      equipmentId: this.equID,
      newStickerName: null,
      reportID: this.reportID,
      reportNo: this.data.data.inspectionSerialNumber,
      inspectionDate: inspectionDate,
      signaturePad1: null,
      signaturePad2: null,
      signaturePad3: null,
      result: result,
      noOfDays: noOfDays,
      proponent: proponent,
      inspectionType: inspectionType,
      loadTest: loadTest,
      type: type
    };

    if (this.signaturePad1._isEmpty === false) {
      this.signaturePad1Base64 = this.signaturePad1.toDataURL();
      this.inputData.signaturePad1 = this.signaturePad1Base64;
    } else {
      this.signaturePad1Base64 = null;
    }

    if (this.signaturePad2._isEmpty === false) {
      this.signaturePad2Base64 = this.signaturePad2.toDataURL();
      this.inputData.signaturePad2 = this.signaturePad2Base64;
    } else {
      this.signaturePad2Base64 = null;
    }

    if (this.signaturePad3._isEmpty === false) {
      this.signaturePad3Base64 = this.signaturePad3.toDataURL();
      this.inputData.signaturePad3 = this.signaturePad3Base64;
    } else {
      this.signaturePad3Base64 = null;
    }
    if (this.inputData.newStickerNumber) {
      let filteredSticker = this.QRStickers.filter(ele => ele._id === this.inputData.newStickerNumber);
      if (filteredSticker.length > 0) {
        this.inputData.newStickerName = filteredSticker[0].qrCode;
      } else {
        this.inputData.newStickerName = null;
      }
    } else {
      this.inputData.stickerNumber = null;
    }

    var tempDeficiencycorrectiveActionDetails = [];
    if (this.deficiencycorrectiveActionDetails[0] !== undefined && this.deficiencycorrectiveActionDetails[0] !== null) {

      for (var k = 0; k < this.deficiencycorrectiveActionDetails.length; k++) {
        var val = {
          "deficiencies": this.deficiencycorrectiveActionDetails[k].deficiencies,
          "correctiveActionTaken": this.deficiencycorrectiveActionDetails[k].correctiveActionTaken,
        };
        tempDeficiencycorrectiveActionDetails.push(val)
      }

    } else {
      tempDeficiencycorrectiveActionDetails = [{
        deficiencies: "",
        correctiveActionTaken: "",
        "addNew": true,
        "remove": false,
        "isActive": true,
      }];
    }
    this.inputData.deficiencycorrectiveActionDetails = tempDeficiencycorrectiveActionDetails;
    this.inputData.createdBy = this.user.data.name;
    this.inputData.receiverId = this.receiverId;

    if (this.isOffline) {
      this.offlineStorageService.rfiReporthandler.getAllReportData(this, function (res) {

        var _this = res.self;
        var data = [];
        var rfiData;
        let months = 0;
        if (_this.inputData.noOfDays === '3 months') {
          months = 3;
        }
        if (_this.inputData.noOfDays === '6 months') {
          months = 6;
        }
        if (_this.inputData.noOfDays === '12 months') {
          months = 12;
        }
        var tempDate = moment(_this.inputData.inspectionDate).subtract(1, 'days')

        let expiryDate = moment(tempDate).add(months, 'M')
        // expiryDate = moment(expiryDate).subtract(1, 'days')
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            rfiData = JSON.parse(res.data.item(i).rfiReportData);
            if (rfiData.scheduleIspectionId.ref === _this.data.data._id) {
              data.push(rfiData);
            }
          }
        }
        if (data.length === 0) {
          let saveData = {
            scheduleIspectionId: { ref: _this.data.data._id },
            reportNo: _this.inputData.reportNo,
            inspectionDate: _this.inputData.inspectionDate,
            inspectionTime: _this.inputData.inspectionTime,
            area: _this.inputData.area,
            orgCode: _this.inputData.orgCode,
            createdBy: _this.user.data.name,
            createdDate: _this.user.data.createdDate,
            reportEmailDetails: _this.existingData,
            technicalReviewer: {
              technicalReviewerName: _this.inputData.technicalReviewerName,
              technicalReviewerBatchNo: _this.inputData.technicalReviewerBatchNo,
              technicalReviewerEmail: _this.inputData.technicalReviewerEmail,
              technicalReviewerMobile: _this.inputData.technicalReviewerMobile
            },
            newStickerNumber: {
              ref: _this.inputData.newStickerNumber,
              stickerNumber: _this.inputData.newStickerName
            },
            oldStickerNumber: _this.inputData.newStickerNumber,
            updatedOldStickerNo: _this.updatedOldStickerNo,
            result: _this.inputData.result,
            noOfDays: _this.inputData.noOfDays,
            expiryDate: expiryDate,
            proponent: _this.inputData.proponent,
            type: _this.inputData.type,
            deficiencycorrectiveActionDetails: [],
            receiverId: _this.receiverId,
            inspectionType: _this.inputData.inspectionType,
            loadTest: _this.inputData.loadTest,
          }
          if (_this.inputData.deficiencycorrectiveActionDetails && _this.inputData.deficiencycorrectiveActionDetails.length > 0) {
            var dData = _this.inputData.deficiencycorrectiveActionDetails;
            saveData.deficiencycorrectiveActionDetails = [];
            for (let i = 0; i < dData.length; i++) {
              var dataz = { deficiencyDetails: { deficiencies: dData[i].deficiencies, correctiveActionTaken: dData[i].correctiveActionTaken } }
              saveData.deficiencycorrectiveActionDetails.push(dataz);
            }
          }

          _this.offlineStorageService.rfiReporthandler.insertscheduleReportDetails(_this, saveData, function (res) {
            if (_this.data.data.activatedStatusName === 'Schedule accepted') {
              _this.data.data.toUpdateStatus = 'Inspection completed. Send for review';
            }
            _this.offlineStorageService.workflowHandler.getWorkflowByRFIId(_this, _this.data.data._id, function (res) {
              let _this = res.self;
              // let resultData = res.data.rows;
              let resultData = [];
              if (res.data.rows.length > 0) {
                for (var i = 0; i < res.data.rows.length; i++) {
                  resultData.push(res.data.rows.item(i));
                }
              }


              var toUpdateData;
              if (resultData.length > 0) {
                for (var i = 0; i < resultData.length; i++) {
                  var parsedData = JSON.parse(resultData[i].workflowData);
                  if (parsedData.status == _this.data.data.toUpdateStatus) {
                    _this.data.data.workFlowId = parsedData._id;
                    _this.data.data.workFlowStatusName = parsedData.status;
                    toUpdateData = parsedData
                  }
                }
                toUpdateData.isActive = true;
                toUpdateData.updatedBy = _this.user.data.name;
              }

              _this.offlineStorageService.workflowSyncHandler.insertWorkFlowHandler(_this, _this.data.data, function (result) {
                _this.offlineStorageService.workflowHandler.updateWorkflow(_this, toUpdateData, function (res) {
                  if (res.status) {
                    _this.offlineStorageService.rfiHandler.getRFIById(_this, _this.data.data, function (res) {
                      var rfiData;
                      if (res.data.length > 0) {
                        rfiData = JSON.parse(res.data.item(0).inspectionData);
                        rfiData.activatedStatus.ref = _this.data.data.workFlowId;
                        rfiData.activatedStatus.status = _this.data.data.workFlowStatusName;
                      }
                      _this.offlineStorageService.rfiHandler.updateSelectedRFI(_this, rfiData, function (res) {
                        let _this = res.self;
                        _this.ngZone.run(() => {
                          setTimeout(() => {
                            _this.snackBar.open(res.data, '', {
                              duration: 5000,
                              verticalPosition: 'bottom',
                            });
                          }, 0);
                        });
                        // _this.snackBar.open(res.data, '', { duration: 2000, verticalPosition: "bottom" });
                        _this.dialogRef.close();
                      });
                    })
                  }
                })
              })
            })

          })
        } else {
          let months = 0;
          if (_this.inputData.noOfDays === '3 months') {
            months = 3;
          }
          if (_this.inputData.noOfDays === '6 months') {
            months = 6;
          }
          if (_this.inputData.noOfDays === '12 months') {
            months = 12;
          }
          var tempDate = moment(_this.inputData.inspectionDate).subtract(1, 'days')

          let expiryDate = moment(tempDate).add(months, 'M')
          // expiryDate = moment(expiryDate).subtract(1, 'days')
          let techData = {
            technicalReviewer: {
              technicalReviewerName: _this.inputData.technicalReviewerName,
              technicalReviewerBatchNo: _this.inputData.technicalReviewerBatchNo,
              technicalReviewerEmail: _this.inputData.technicalReviewerEmail,
              technicalReviewerMobile: _this.inputData.technicalReviewerMobile
            }
          }
          rfiData.scheduleIspectionId.ref = _this.inputData.scheduleIspectionId;
          rfiData.reportNo = _this.inputData.reportNo;
          rfiData.inspectionTime = _this.inputData.inspectionTime;
          rfiData.inspectionDate = _this.inputData.inspectionDate;
          rfiData.area = _this.inputData.area;
          rfiData.orgCode = _this.inputData.orgCode;
          rfiData.newStickerNumber.ref = _this.inputData.newStickerNumber;
          rfiData.newStickerNumber.stickerNumber = _this.inputData.newStickerName;
          rfiData.createdBy = _this.inputData.createdBy;
          rfiData.reportEmailDetails = _this.existingData;
          rfiData.technicalReviewer = techData.technicalReviewer;
          // rfiData.technicalReviewer.technicalReviewerName = _this.inputData.technicalReviewerName;
          // rfiData.technicalReviewer.technicalReviewerBatchNo = _this.inputData.technicalReviewerBatchNo;
          // rfiData.technicalReviewer.technicalReviewerEmail = _this.inputData.technicalReviewerEmail;
          // rfiData.technicalReviewer.technicalReviewerMobile = _this.inputData.technicalReviewerMobile;
          rfiData.oldStickerNumber = _this.inputData.newStickerNumber;
          rfiData.updatedOldStickerNo = _this.updatedOldStickerNo,
            rfiData.result = _this.inputData.result;
          rfiData.noOfDays = _this.inputData.noOfDays;
          rfiData.expiryDate = expiryDate;
          rfiData.proponent = _this.inputData.proponent;
          rfiData.type = _this.inputData.type
          rfiData.receiverId = _this.receiverId;
          rfiData.inspectionType = _this.inputData.inspectionType;
          rfiData.loadTest = _this.inputData.loadTest;
          if (data[0]._id) {
            rfiData._id = data[0]._id;
          }



          if (_this.inputData.deficiencycorrectiveActionDetails && _this.inputData.deficiencycorrectiveActionDetails.length > 0) {
            var dData = _this.inputData.deficiencycorrectiveActionDetails;
            rfiData.deficiencycorrectiveActionDetails = [];
            for (let i = 0; i < dData.length; i++) {
              var dataz = { deficiencyDetails: { deficiencies: dData[i].deficiencies, correctiveActionTaken: dData[i].correctiveActionTaken } }
              rfiData.deficiencycorrectiveActionDetails.push(dataz);
            }
          }
          _this.offlineStorageService.rfiReporthandler.updateRFIReportById(_this, rfiData, function (res) {
            let _this = res.self;
            if (res.status) {
              _this.offlineStorageService.attachment
              _this.ngZone.run(() => {
                setTimeout(() => {
                  _this.snackBar.open(res.data, '', {
                    duration: 5000,
                    verticalPosition: 'bottom',
                  });
                }, 0);
              });
              // _this.snackBar.open(res.data, '', { duration: 2000, verticalPosition: "bottom" });
              _this.dialogRef.close();
            }
          })
        }
      })
      if (this.signaturePad1Base64) {
        var filesize = 1000
        var inputData1 = {
          refID: this.data.data._id,
          module: 'Inspector signature',
          filetype: 'image/png',
          filename: 'Inspector signature',
          filesize: filesize,
          content: this.signaturePad1Base64,
          createdBy: this.user.data.name,
          _id: null,
          pid: null
        }
        var data1 = {
          refID: this.data.data._id,
          module: 'Inspector signature'
        }
        this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data1, function (res) {
          let _this = res.self;
          if (res.data.length > 0) {
            let resultData = JSON.parse(res.data.item(0).attachmentData);
            if (resultData._id) {
              inputData1._id = resultData._id;
            }
            inputData1.pid = res.data[0].pid;
            _this.offlineStorageService.attachmentHandler.updateAttachmentsInOffline(_this, inputData1, function (result) {
            })
          } else {
            _this.offlineStorageService.attachmentHandler.insertAttachmentsInOffline(_this, inputData1, function (result) {
            })
          }
        })
      }
      if (this.signaturePad2Base64) {
        var filesize = 1000

        var inputData2 = {
          refID: this.data.data._id,
          module: 'Reviewer signature',
          filetype: 'image/png',
          filename: 'Reviewer signature',
          filesize: filesize,
          content: this.signaturePad2Base64,
          createdBy: this.user.data.name,
          _id: null,
          pid: null
        }
        var data2 = {
          refID: this.data.data._id,
          module: 'Reviewer signature'
        }
        this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data2, function (res) {
          let _this = res.self;
          if (res.data.length > 0) {
            let resultData = JSON.parse(res.data.item(0).attachmentData);
            if (resultData._id) {
              inputData2._id = resultData._id;
            }
            inputData2.pid = res.data[0].pid;

            _this.offlineStorageService.attachmentHandler.updateAttachmentsInOffline(_this, inputData2, function (result) {
            })
          } else {
            _this.offlineStorageService.attachmentHandler.insertAttachmentsInOffline(_this, inputData2, function (result) {
            })
          }
        })
      }
      if (this.signaturePad3Base64) {
        var filesize = 1000

        var inputData3 = {
          refID: this.data.data._id,
          module: 'Load test signature',
          filetype: 'image/png',
          filename: 'Load test signature',
          filesize: filesize,
          content: this.signaturePad3Base64,
          createdBy: this.user.data.name,
          _id: null,
          pid: null
        }
        var data3 = {
          refID: this.data.data._id,
          module: 'Load test signature'
        }
        this.offlineStorageService.attachmentHandler.getAttachmentByRefID(this, data3, function (res) {
          let _this = res.self;
          if (res.data.length > 0) {
            let resultData = JSON.parse(res.data.item(0).attachmentData);
            if (resultData._id) {
              inputData3._id = resultData._id;
            }
            inputData3.pid = res.data[0].pid;

            _this.offlineStorageService.attachmentHandler.updateAttachmentsInOffline(_this, inputData3, function (result) {
            })
          } else {
            _this.offlineStorageService.attachmentHandler.insertAttachmentsInOffline(_this, inputData3, function (result) {
            })
          }
        })
      }
    } else {
      this.scheduleInspectionReportService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  updateResult(data) {
    // if (environment.mobileApp) {
    //   this.result.setValue(data);
    // } else {
    let value = data.value;
    let result = [];
    result = _.filter(this.QRStickers, ele => { return ele._id === value });
    this.result.setValue(result[0].type)
    //}
  }

  addReportEmail() {
    const dialogRef = this.dialog.open(ScheduleInspectionReportEmailFormComponent, {
      data: { data: this.data.data, existingData: this.existingData },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReportEmailData(result);
    });
  }

  getReportEmailData(data) {
    if (data && data.data) {
      this.existingData = data.data;
    }
    this.dataSource = new MatTableDataSource(this.existingData);
  }

  removeEmailFromList(row) {
    let temp = this.existingData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].ref == row.ref) {
        this.existingData.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource(this.existingData);
  }

  btnAddNewReceiver() {
    const dialogRef = this.dialog.open(ReceiverFormComponent, {
      data: { data: this.data.data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        this.patchReceiverValue(result.data)
      }
    });
  }

  patchReceiverValue(data) {
    if (data) {
      this.technicalReviewerName.setValue(data.name);
      this.technicalReviewerBatchNo.setValue(data.badgeNo);
      this.technicalReviewerEmail.setValue(data.emailID);
      this.technicalReviewerMobile.setValue(data.telephone);
      this.receiverId = data._id;
    }

  }
  patchEquipmentValue() {
    if (this.data.data.inspectionSerialNumber) {
      this.reportNo.setValue(this.data.data.inspectionSerialNumber)
    }
    if (this.data.data.inspectionDate) {
      // var date = moment(new Date(this.data.data.inspectionDate)).format('DD/MM/YYYY');
      this.inspectionDate.setValue(this.data.data.inspectionDate);
    }


  }

  selectFromExisting() {
    const dialogRef = this.dialog.open(SelectReceiverComponent, {
      data: { data: this.data.data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchReceiverValue(result);
    });
  }

  convertISO(string) {
    if (string) {
      var date
      date = string.split("/");    // ["29", "1", "2016"]
      return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
    }
    else {
      return null;
    }
  }
  getErrorNewStickerNumber() {
    return this.newStickerNumber.hasError('required')
      ? 'Sticker Number is required'
      : '';
  }
  // getErrorEquipmentNo() {
  //   return this.equipmentNo.hasError('required')
  //     ? 'Equipment Number is required'
  //     : '';
  // }

  getErrorInspectionTime() {
    return this.inspectionTime.hasError('required')
      ? 'Inspection Time is required'
      : '';
  }

  getErrorProponent() {
    return this.proponent.hasError('required')
      ? 'Proponent of days is required'
      : '';
  }
  getErrorType() {
    return this.type.hasError('required')
      ? 'Type is required'
      : '';
  }


  getErrorResult() {
    return this.result.hasError('required')
      ? 'Result is required'
      : '';
  }

  getErrortechnicalReviewerEmail() {
    return this.technicalReviewerEmail.hasError('required')
      ? 'Email is required'
      : '';
  }
  getErrortechnicalReviewerBatchNo() {
    return this.technicalReviewerBatchNo.hasError('required')
      ? 'Badge no is required'
      : '';
  }
  getErrortechnicalReviewerName() {
    return this.technicalReviewerName.hasError('required')
      ? 'Technical reviewer is required'
      : '';
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    if (
      this.reportNo.dirty ||
      this.inspectionDate.dirty ||
      this.newStickerNumber.dirty ||
      this.inspectionTime.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  clearReceiverDetails() {
    if (this.technicalReviewerName.value) {
      let dialogRef = this.dialog.open(ReceiverDetailDialogComponent, {
        data: this.data,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.technicalReviewerBatchNo.reset();
          this.technicalReviewerEmail.reset();
          this.technicalReviewerName.reset();
          this.technicalReviewerMobile.reset();
        }
      });
    }
  }
  get f() {
    return this.newStickerNumber;
  }

  filterStickers(val) {
    val = val.toLowerCase();
    this.QRStickers = this.qrStickers.filter(function (d) {
      return (d.qrCode.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  additionalSave(inspectionDate: String,
    inspectionTime: String,
    area: String,
    orgCode: String,
    newStickerNumber: String,
    newStickerNo: String,
    deficiencies: String,
    result: String,
    noOfDays: String,
    proponent: String,
    correctiveActionTaken: String,
    technicalReviewerName: String,
    technicalReviewerBatchNo: String,
    technicalReviewerEmail: String,
    technicalReviewerMobile: Number,
    inspectionType: String,
    loadTest: String,
    type: String
  ): void {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      data: { data: this.data.data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.inputData = {
          scheduleIspectionId: this.data.data._id,
          inspectionTime: inspectionTime,
          area: area,
          orgCode: orgCode,
          newStickerNumber: newStickerNumber,
          updatedOldStickerNo: this.updatedOldStickerNo,
          deficiencies: deficiencies,
          correctiveActionTaken: correctiveActionTaken,
          technicalReviewerName: technicalReviewerName,
          technicalReviewerBatchNo: technicalReviewerBatchNo,
          technicalReviewerEmail: technicalReviewerEmail,
          technicalReviewerMobile: technicalReviewerMobile,
          reportEmailDetails: this.existingData,
          equipmentId: this.equID,
          newStickerName: null,
          reportID: this.reportID,
          reportNo: this.data.data.inspectionSerialNumber,
          inspectionDate: inspectionDate,
          signaturePad1: null,
          signaturePad2: null,
          signaturePad3: null,
          result: result,
          noOfDays: noOfDays,
          proponent: proponent,
          inspectionType: inspectionType,
          loadTest: loadTest,
          type: type
        };

        if (this.signaturePad1._isEmpty === false) {
          this.signaturePad1Base64 = this.signaturePad1.toDataURL();
          this.inputData.signaturePad1 = this.signaturePad1Base64;
        } else {
          this.signaturePad1Base64 = null;
        }

        if (this.signaturePad2._isEmpty === false) {
          this.signaturePad2Base64 = this.signaturePad2.toDataURL();
          this.inputData.signaturePad2 = this.signaturePad2Base64;
        } else {
          this.signaturePad2Base64 = null;
        }

        if (this.signaturePad3._isEmpty === false) {
          this.signaturePad3Base64 = this.signaturePad3.toDataURL();
          this.inputData.signaturePad3 = this.signaturePad3Base64;
        } else {
          this.signaturePad3Base64 = null;
        }
        if (this.inputData.newStickerNumber) {
          let filteredSticker = this.QRStickers.filter(ele => ele._id === this.inputData.newStickerNumber);
          if (filteredSticker.length > 0) {
            this.inputData.newStickerName = filteredSticker[0].qrCode;
          } else {
            this.inputData.newStickerName = null;
          }
        } else {
          this.inputData.stickerNumber = null;
        }

        var tempDeficiencycorrectiveActionDetails = [];
        if (this.deficiencycorrectiveActionDetails[0] !== undefined && this.deficiencycorrectiveActionDetails[0] !== null) {

          for (var k = 0; k < this.deficiencycorrectiveActionDetails.length; k++) {
            var val = {
              "deficiencies": this.deficiencycorrectiveActionDetails[k].deficiencies,
              "correctiveActionTaken": this.deficiencycorrectiveActionDetails[k].correctiveActionTaken,
            };
            tempDeficiencycorrectiveActionDetails.push(val)
          }

        } else {
          tempDeficiencycorrectiveActionDetails = [{
            deficiencies: "",
            correctiveActionTaken: "",
            "addNew": true,
            "remove": false,
            "isActive": true,
          }];
        }
        this.inputData.deficiencycorrectiveActionDetails = tempDeficiencycorrectiveActionDetails;
        this.inputData.createdBy = this.user.data.name;
        this.inputData.receiverId = this.receiverId;
        this.scheduleInspectionReportService.updateRecord(this.inputData).subscribe(res => {
          if (res['statusBool']) {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.dialogRef.close();
          } else {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      }
    });
  }

}

