import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { EquipmentService } from '../equipment.service';
import { ProjectService } from '../../../master/project/project.service';
import { ManufactureService } from '../../../master/manufacture/manufacture.service';
import { TypeService } from '../../../master/type/type.service';
import { LocationService } from '../../../master/location/location.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
import { ScheduleInspectionService } from '../../scheduleInspection/schedule-inspection.service';
import { CategoryService } from '../../../master/category/category.service';
import { EquipmentCompanyService } from '../../../master/equipmentCompany/equipment-company.service';
import { LocationFormComponent } from '../../location/location-form/location-form.component'
import { ManufactureFormComponent } from '../../manufacture/manufacture-form/manufacture-form.component'
import { TypeFormComponent } from '../../type/type-form/type-form.component'
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';


@Component({
  selector: 'app-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class EquipmentFormComponent implements OnInit {

  equipmentForm: any;
  projects: any;
  anotherProjects: any;
  manufactures: any;
  anotherManufacture: any;
  public locations: any;
  public anotherLocations: any;
  types: any;
  anothertypes: any;
  user: any;
  categories: any;
  anotherCategory: any;
  equipmentCompanies: any;
  anotherEquipmentCompanies: any;
  // For Save
  projectName = null;
  projectNumber = null;
  manufactureName = null;
  typeName = null;
  locationName = null;
  categoryName = null;
  rowId = null;
  isSave = false;
  isUpdate = false;
  scheduleData: any;
  isColumn = true;
  isRow = false;
  isOffline: Boolean;
  constructor(
    private fb: FormBuilder, private router: Router,
    private dialogRef: MatDialogRef<EquipmentFormComponent>,
    private projectService: ProjectService,
    private manufactureService: ManufactureService,
    private typeService: TypeService, private dialog: MatDialog,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private equipmentService: EquipmentService,
    private scheduleInspectionService: ScheduleInspectionService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private categoryService: CategoryService,
    private equipmentCompanyService: EquipmentCompanyService, private offlineStorage: OfflineStorageService, private ngZone: NgZone
  ) {
    this.equipmentForm = fb.group({
      'project': [null, Validators.compose([Validators.required])],
      'equipmentNumber': [null, Validators.compose([Validators.required])],
      'equipmentSerialNumber': [null, Validators.compose([Validators.required])],
      'manufacture': [null],
      'model': [null],
      'type': [null],
      'location': [null],
      'capacity': [null],
      'updatedPreviousStickerNumber': [null],
      // 'updatedExpiryDate': [null],
      'category': [null, Validators.compose([Validators.required])],
      'equipmentCompanyRefID': [null]
    });
  }

  ngOnInit() {

    if (this.data._id) {
      this.rowId = this.data._id;
      this.isUpdate = true;
      this.patchValue();
    } else {
      this.isSave = true;
    }
    let value = localStorage.getItem('isOffline');


    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    if (this.data) {
      if (this.data.data) {
        if (this.data.data.report == 'ScheduleReport') {
          this.isColumn = false;
          this.isRow = true;
          this.getScheduleData();
        }
      }
    }
    this.user = this.getFromLocalStorage('userDet');
    this.getProjects();
    this.getManufactures();
    this.getTypes();
    this.getLocations();
    this.getCategories();
    this.getEquipmentCompanies();
  }

  getScheduleData() {
    if (this.isOffline == true) {
      var data1 = {
        _id: this.data.data.data.inspectionID,
      }
      var scheduleInspection = [];
      this.offlineStorage.scheduleInspectionhandler.getScheduleInspectionById(this, data1, function (res) {
        let _this = res.self;

        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            scheduleInspection.push(JSON.parse(res.data.item(i).scheduleInspectionData));
          }
        }
        if (scheduleInspection && scheduleInspection.length > 0) {
          _this.scheduleData = scheduleInspection[0];
        }
      });
    } else {
      this.scheduleInspectionService.getScheduleDataByInspectionID(this.data.data.data).subscribe(res => {
        if (res) {
          if (res.length > 0) {
            this.scheduleData = res[0];

          }
        }
      });
    }
  }
  save() {
    var inputData = {
      project: this.equipmentForm.controls.project.value,
      equipmentNumber: this.equipmentForm.controls.equipmentNumber.value,
      equipmentSerialNumber: this.equipmentForm.controls.equipmentSerialNumber.value,
      manufacture: this.equipmentForm.controls.manufacture.value,
      model: this.equipmentForm.controls.model.value,
      type: this.equipmentForm.controls.type.value,
      location: this.equipmentForm.controls.location.value,
      capacity: this.equipmentForm.controls.capacity.value,
      updatedPreviousStickerNumber: this.equipmentForm.controls.updatedPreviousStickerNumber.value,
      category: this.equipmentForm.controls.category.value,
      equipmentCompanyRefID: this.equipmentForm.controls.equipmentCompanyRefID.value,
      // updatedExpiryDate: this.equipmentForm.controls.updatedExpiryDate.value,
      projectName: null,
      projectNumber: null,
      locationName: null,
      manufactureName: null,
      typeName: null,
      createdBy: null,
      updatedBy: null,
      rowId: null,
      isValueChange: false,
      categoryName: null
    }
    if (inputData.project) {
      let filteredProject = this.projects.filter(ele => ele._id === inputData.project);
      if (filteredProject.length > 0) {
        this.projectName = filteredProject[0].projectName;
        this.projectNumber = filteredProject[0].projectNo;
      }
    }
    if (inputData.manufacture) {
      let filteredManufacture = this.manufactures.filter(ele => ele._id === inputData.manufacture);
      if (filteredManufacture.length > 0) {
        this.manufactureName = filteredManufacture[0].name;
      }
    }
    if (inputData.type) {
      let filteredType = this.types.filter(ele => ele._id === inputData.type);
      if (filteredType.length > 0) {
        this.typeName = filteredType[0].name;
      }
    }
    if (inputData.location) {
      let filteredLocation = this.locations.filter(ele => ele._id === inputData.location);
      if (filteredLocation.length > 0) {
        this.locationName = filteredLocation[0].name;
      }
    }
    if (inputData.category) {
      let filteredCategory = this.categories.filter(ele => ele._id === inputData.category);
      if (filteredCategory.length > 0) {
        this.categoryName = filteredCategory[0].name;
      }
    }
    inputData.projectName = this.projectName;
    inputData.projectNumber = this.projectNumber;
    inputData.locationName = this.locationName;
    inputData.typeName = this.typeName;
    inputData.manufactureName = this.manufactureName;
    inputData.categoryName = this.categoryName;
    inputData.createdBy = this.user.data.name;
    inputData.updatedBy = this.user.data.name;
    inputData.rowId = this.rowId;
    inputData['manualSticker'] = inputData.updatedPreviousStickerNumber;
    if (this.data.data) {
      inputData.category = this.data.data.data.category.ref;
      inputData.categoryName = this.data.data.data.category.categoryName
    }
    if (this.scheduleData) {
      inputData.project = this.scheduleData.project.ref;
      inputData.projectName = this.scheduleData.project.name;
      inputData.projectNumber = this.scheduleData.project.number;
    }

    if (this.rowId) {
      this.equipmentService.updateRecord(inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      }, err => {
        if (err.error.statusHttp === 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
    else {
      if (this.isOffline == true) {
        let equipment = [];
        let isExist = false;

        this.offlineStorage.equipmentHandler.getExistingEquipmentByCategoryID(this, function (res) {
          let _this = res.self;
          if (res && res.data && (res.data.length > 0)) {
            for (let i = 0; i < res.data.length; i++) {
              equipment.push(JSON.parse(res.data.item(i).equipmentData));
            }
            if (equipment && equipment.length > 0) {
              equipment.forEach(element => {
                if ((element._id !== null && element._id !== undefined && element._id !== '') && element.equipmentSerialNumber === inputData.equipmentSerialNumber.toUpperCase().trim()) {
                  isExist = true;
                }
              });
            }
            if (!isExist) {
              let equipmentData = {
                project: {
                  ref: inputData.project,
                  name: inputData.projectName,
                  number: inputData.projectNumber
                },
                manufacture: {
                  ref: inputData.manufacture,
                  name: inputData.manufactureName
                },
                category: {
                  ref: inputData.category,
                  name: inputData.categoryName
                },
                equipmentNumber: inputData.equipmentNumber.toUpperCase(),
                model: inputData.model,
                equipmentSerialNumber: inputData.equipmentSerialNumber.toUpperCase(),
                type: {
                  ref: inputData.type,
                  name: inputData.typeName
                },
                location: {
                  ref: inputData.location,
                  name: inputData.locationName
                },
                capacity: inputData.capacity,
                updatedPreviousStickerNumber: inputData.updatedPreviousStickerNumber,
                createdBy: inputData.createdBy,
                // createdDate : new Date(),
                equipmentCompanyRefID: inputData.equipmentCompanyRefID,
                manualSticker: inputData.updatedPreviousStickerNumber
              }
              _this.offlineStorage.equipmentHandler.insertOfflineEquipmentDetails(_this, equipmentData, function (res) {
                var convertDate = new Date(_this.data.data.data.createdDate);
                _this.data.data.data.createdDate = convertDate.toISOString;
                var rfiData = _this.data.data.data;
                rfiData.equipment = { ref: res.data.insertId };
                _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, rfiData, function (result) {
                  var id = _this.data.data.data._id;

                  _this.offlineStorage.rfiReporthandler.getRFIReportDataByRFIId(_this, id, function (res) {
                    let _this = res.self, temp = [];
                    if (res && res.data && res.data.length > 0) {
                      for (let i = 0; i < res.data.length; i++) {
                        temp.push(JSON.parse(res.data.item(i).rfiReportData));
                      }
                    }

                    if (temp.length > 0) {
                      temp[0].updatedOldStickerNo = inputData.updatedPreviousStickerNumber;
                      _this.offlineStorage.rfiReporthandler.updateRFIReportById(_this, temp[0], function (res) {
                        _this.ngZone.run(() => {
                          setTimeout(() => {
                            _this.snackBar.open('Saved successfully', '', {
                              duration: 5000,
                              verticalPosition: 'bottom',
                            });
                          }, 0);
                        })
                        _this.dialogRef.close();
                        console.log(res);
                      });
                    } else {
                      _this.ngZone.run(() => {
                        setTimeout(() => {
                          _this.snackBar.open('Saved successfully', '', {
                            duration: 5000,
                            verticalPosition: 'bottom',
                          });
                        }, 0);
                      })
                      _this.dialogRef.close();
                      console.log(res);
                    }
                  });
                })

              });
            }
            else {

              _this.ngZone.run(() => {
                setTimeout(() => {
                  _this.snackBar.open('Duplicate entry', '', {
                    duration: 5000,
                    verticalPosition: 'bottom',
                  });
                }, 0);
              });
            }
          }
        });

      } else {
        this.equipmentService.save(inputData).subscribe(result => {
          if (result['statusBool']) {
            if (this.data.data !== undefined && this.data.data !== null && this.data.data !== '') {
              if (this.data.data.report === 'ScheduleReport') {
                var data = {
                  equipmentID: result['data']._id,
                  rfiID: this.data.data.id
                }
                this.scheduleInspectionService.updateEquipmentNumberById(data).subscribe(res => {
                  this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
                  this.dialogRef.close();
                });
              }
            } else {
              this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
              this.dialogRef.close();
            }
          } else {
            this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        }, err => {
          if (err.error.statusHttp === 412) {
            this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      }
    }


  }

  get f() {
    return this.equipmentForm.controls;
  }
  addLocation() {
    let dialogRef = this.dialog.open(LocationFormComponent, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchLocationValue(result)
    });
  }
  patchLocationValue(data) {
    if (this.isOffline) {
      if (data.string === 'new') {
        this.anotherLocations.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.locations.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.equipmentForm.patchValue({ location: data._id });
      }
    } else {
      this.locationService.getLocationIDByName(data).subscribe(res => {
        console.log(res);
        if (res.length > 0) {
          this.ngOnInit();
          this.equipmentForm.patchValue({ location: res[0]._id });
        }

      });
    }
  }
  addManufacture() {
    let dialogRef = this.dialog.open(ManufactureFormComponent, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchManufactureValue(result)
    });
  }
  patchManufactureValue(data) {
    if (this.isOffline) {
      if (data.string === 'new') {
        this.anotherManufacture.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.manufactures.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.equipmentForm.patchValue({ manufacture: data._id });
      }
    } else {
      this.manufactureService.getManufactureIDByName(data).subscribe(res => {
        console.log(res);
        if (res.length > 0) {
          this.ngOnInit();
          this.equipmentForm.patchValue({ manufacture: res[0]._id });
        }

      });
    }
  }
  addType() {
    let dialogRef = this.dialog.open(TypeFormComponent, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchTypeValue(result)
    });
  }
  patchTypeValue(data) {
    if (this.isOffline) {
      if (data.string === 'new') {
        this.anothertypes.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.types.push({ "_id": data._id, "name": data.name, "createdBy": data.createdBy, "description": data.description });
        this.equipmentForm.patchValue({ type: data._id });
      }
    } else {
      this.typeService.getTypeIDByName(data).subscribe(res => {
        console.log(res);
        if (res.length > 0) {
          this.ngOnInit();
          this.equipmentForm.patchValue({ type: res[0]._id });
        }

      });
    }
  }
  patchValue() {
    if (this.data.project) {
      if (this.data.project.ref) {
        this.equipmentForm.patchValue({ project: this.data.project.ref });
      }
    }
    if (this.data.manufacture) {
      if (this.data.manufacture.ref) {
        this.equipmentForm.patchValue({ manufacture: this.data.manufacture.ref });
      }
    }
    if (this.data.location) {
      if (this.data.location.ref) {
        this.equipmentForm.patchValue({ location: this.data.location.ref });
      }
    }
    if (this.data.type) {
      if (this.data.type.ref) {
        this.equipmentForm.patchValue({ type: this.data.type.ref });
      }
    }
    if (this.data.category) {
      if (this.data.category.ref) {
        this.equipmentForm.patchValue({ category: this.data.category.ref });
      }
    }
    this.equipmentForm.patchValue({
      equipmentNumber: this.data.equipmentNumber,
      equipmentSerialNumber: this.data.equipmentSerialNumber,
      model: this.data.model,
      capacity: this.data.capacity,
      updatedPreviousStickerNumber: this.data.updatedPreviousStickerNumber,
      // updatedExpiryDate: this.convertISO(this.data.updatedExpiryDate)
      equipmentCompanyRefID: this.data.equipmentCompanyRefID
    });
  }

  convertISO(string) {
    if (string) {
      var date;
      date = string.split('/');    // ["29", "1", "2016"]
      return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
    } else {
      return null;
    }
  }

  getProjects() {
    this.projectService.getProjects().subscribe(result => {
      this.projects = result;
      this.anotherProjects = this.projects;
    });
  }

  filterProject(val) {
    val = val.toLowerCase();
    this.anotherProjects = this.projects.filter(function (d) {
      return (d.projectName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.projectNo.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getManufactures() {
    if (this.isOffline == true) {
      this.offlineStorage.manufacturehandler.getManufacture(this, function (res) {
        let _this = res.self;
        var tempManufactureData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data.item(i)._id === undefined || res.data.item(i)._id === 'undefined') {
              let manufactureData = JSON.parse(res.data.item(i).manufactureData);
              tempManufactureData.push({
                _id: res.data.item(i).pid,
                name: manufactureData.name,
                description: manufactureData.description !== null && manufactureData.description !== undefined ? manufactureData.description : '',
                createdBy: manufactureData.createdBy,
              })
            } else {
              tempManufactureData.push(JSON.parse(res.data.item(i).manufactureData));
            }
          }
          _this.bindValue(tempManufactureData, 'Manufacture');

        }
      });
    } else {
      this.manufactureService.getManufacture().subscribe(result => {
        this.manufactures = result;
        this.anotherManufacture = this.manufactures;
      });
    }
  }

  filterManufacture(val) {
    val = val.toLowerCase();
    this.anotherManufacture = this.manufactures.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getLocations() {
    if (this.isOffline == true) {
      this.offlineStorage.locationhandler.getLocations(this, function (res) {
        let _this = res.self;
        var tempLocationData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data.item(i)._id === undefined || res.data.item(i)._id === 'undefined') {
              let locationData = JSON.parse(res.data.item(i).locationData);
              tempLocationData.push({
                _id: res.data.item(i).pid,
                name: locationData.name,
                description: locationData.description !== null && locationData.description !== undefined ? locationData.description : '',
                createdBy: locationData.createdBy,
              })
            } else {
              tempLocationData.push(JSON.parse(res.data.item(i).locationData));
            }
          }
          _this.bindValue(tempLocationData, 'Location');

        }
      });
    } else {
      this.locationService.getLocations().subscribe(result => {
        this.locations = result;
        this.anotherLocations = this.locations;
      });
    }
  }

  filterLocation(val) {
    val = val.toLowerCase();
    this.anotherLocations = this.locations.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getCategories() {
    this.categoryService.getCategory().subscribe(result => {
      this.categories = result;
      this.anotherCategory = this.categories;
    });
  }

  filterCategory(val) {
    val = val.toLowerCase();
    this.anotherCategory = this.categories.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getEquipmentCompanies() {
    if (this.isOffline == true) {
      this.offlineStorage.equipmentCompanyhandler.getEquipmentCompany(this, function (res) {
        let _this = res.self;
        var tempEquipmentCompanyData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempEquipmentCompanyData.push(JSON.parse(res.data.item(i).equipmentCompanyData));
            _this.bindValue(tempEquipmentCompanyData, 'Equipment Company');

          }
        }
      });
    } else {
      this.equipmentCompanyService.getEquipmentCompany().subscribe(result => {
        this.equipmentCompanies = result;
        this.anotherEquipmentCompanies = this.equipmentCompanies;
      });
    }
  }

  filterEquipmentCompanies(val) {
    val = val.toLowerCase();
    this.anotherEquipmentCompanies = this.equipmentCompanies.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.abbr.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getTypes() {
    if (this.isOffline == true) {
      this.offlineStorage.typehandler.getTypes(this, function (res) {
        let _this = res.self;
        var tempTypeData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {

            if (res.data.item(i)._id === undefined || res.data.item(i)._id === 'undefined') {
              let typeData = JSON.parse(res.data.item(i).typeData);
              tempTypeData.push({
                _id: res.data.item(i).pid,
                name: typeData.name,
                description: typeData.description !== null && typeData.description !== undefined ? typeData.description : '',
                createdBy: typeData.createdBy,
              })
            } else {
              tempTypeData.push(JSON.parse(res.data.item(i).typeData));
            }
          }
          _this.bindValue(tempTypeData, 'Type');

        }
      });
    } else {
      this.typeService.getTypes().subscribe(result => {
        this.types = result;
        this.anothertypes = this.types;
      });
    }
  }

  filterType(val) {
    val = val.toLowerCase();
    this.anothertypes = this.types.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  bindValue(data, param) {
    if (param == 'Location') {
      this.locations = data;
      this.anotherLocations = this.locations;
    }
    if (param == 'Manufacture') {
      this.manufactures = data;
      this.anotherManufacture = this.manufactures;
    }
    if (param == 'Type') {
      this.types = data;
      this.anothertypes = this.types;
    }
    if (param == 'Equipment Company') {
      this.equipmentCompanies = data;
      this.anotherEquipmentCompanies = this.equipmentCompanies;
    }
  }

  btnClose() {
    if (this.equipmentForm.dirty) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: true,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close(true);
        }
      });
    } else {
      this.dialogRef.close(true);
    }
  }

}
