import { Component, OnInit, NgZone, ViewChild, DebugElement } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from '../../services/report/report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../general/dateformat-form';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSnackBar, MatOption } from '@angular/material';
import * as moment from 'moment';
import { GeoptionService } from '../../services/geoption/geoption.service'
import * as _ from 'underscore';
import { environment } from '../../../environments/environment';
import { FileService } from '../../services/files/file.service';
import { ExcelService } from '../../excel.service';
import { UserService } from '../../admin/user/user.service';

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class WeeklyReportComponent implements OnInit {

  reportForm: any;
  public response: any;
  public url: any;
  public safeSrc: SafeResourceUrl;
  src: any;
  image: any;
  showDownload = false;
  temp: any;
  companyData: any;
  countData: any;
  types: any;
  anotherTypes: any;
  typeName = '';
  inspectorList: any;
  anotherinspectorList: any;
  @ViewChild('allSelectedComp', { static: true }) private allSelectedComp: MatOption;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private geoptionService: GeoptionService,
    private fileService: FileService,
    private excelService: ExcelService,
    private snackbar : MatSnackBar,
    private inspectorService: UserService,
    private ngZone: NgZone
  ) {
    this.reportForm = fb.group({
      'fromDate': [null, Validators.compose([Validators.required])],
      'toDate': [null, Validators.compose([Validators.required])],
      'type': [null, Validators.compose([Validators.required])],
      'inspector': [null],


    });
  }

  ngOnInit() {

    this.getInspectors();
    this.getTypes();
  }

  get f() {
    return this.reportForm.controls;
  }

  getTypes() {
    this.geoptionService.getRecordByOptionName('ContractType').subscribe(res => {
      this.types = res[0].option;
      this.anotherTypes = res[0].option;
      this.reportForm.patchValue({ type: this.types[0]._id });
    });
  }

  getInspectors() {

    this.inspectorService.getInspectors().subscribe(result => {

      this.inspectorList = result;
      this.anotherinspectorList = this.inspectorList;
      // this.dropdownList=this.inspectorList;
    });
  }
  tosslePerComp(all) {
    if (this.allSelectedComp.selected) {
      this.allSelectedComp.deselect();
      return false;
    }
    if (this.reportForm.controls.inspector.value.length === this.anotherinspectorList.length) {
      this.allSelectedComp.select();
    }

  }
  selectAllComp() {
    if (this.allSelectedComp.selected) {
      this.reportForm.controls.inspector
        .patchValue([...this.anotherinspectorList.map(item => item), 0]);
    } else {
      this.reportForm.controls.inspector.patchValue([]);
    }

  }
  getData() {
    this.showDownload = false;

    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;
    let exactDate = toDate;
    let inspector = this.reportForm.controls.inspector.value;

    if (!fromDate) {
      this.snackBar.open('From date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!toDate) {
      this.snackBar.open('To date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    toDate = toDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate).toISOString();

    exactDate = exactDate.setDate(exactDate.getDate() - 1);
    exactDate = new Date(exactDate).toISOString();

    var selectedType = [];
    selectedType = _.filter(this.types, ele => { return ele._id == this.reportForm.controls.type.value });
    if (selectedType.length > 0) {
      this.typeName = selectedType[0].optionValue.toLowerCase();
    }
    var typeName = selectedType[0].optionValue;
    var typeId = selectedType[0]._id;

    var data = {
      fromDate: fromDate,
      toDate: toDate,
      showDate: exactDate,
      type: typeId,
      typeName: typeName,
      inspector: inspector,
    }
    this.reportService.generateWeeklyReport(data).subscribe(res => {
      if (res['statusBool']) {
        this.response = res['data'];
        this.temp = res['data2'];
        this.companyData = res['companyData'];
        this.countData = res['count'];

        this.showDownload = true;
        const byteArray = new Uint8Array(atob(this.response).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        this.url = window.URL.createObjectURL(blob);
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.src = this.url;
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }

    })
  }

  public downloadFn() {
    var string1 = 'Weekly Report_';
    var string2 = moment(new Date(this.reportForm.controls.toDate.value)).format('DD_MM_YYYY');
    var fileName = string1 + string2 + '.pdf';

    var blob = this.fileService._base64ToArrayBuffer(this.response, 'application/pdf');
    if (environment.mobileApp) {
      this.fileService.openFileOnMobile(blob, fileName, 'application/pdf')
    } else {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      }
    }

  }

  exportExcel() {
    if (this.typeName === 'aramco direct') {
      var data1 = [];
      var i = 1;
      this.companyData['trackingSystem'] = 'ARAMCO Elevators & Associated Equipment Tracking System';
      this.temp.forEach(element => {
        var val = {
          "SEQ no": i,
          "TEL #": element.tel,
          'P.O': element.rpoNo,
          'Equipment owner': element.equOwner,
          'Proponent': element.proponent,
          'Proponent org code': element.orgCode,
          'Equipment type': element.equType,
          'Equipment identification number': element.equIdNum,
          'Equipment serial number': element.equipmentSerialNumber,
          'Equipment location': element.equLoc,
          'Area': element.area,
          'Inspection type(P.I)': element.pi,
          'Inspection type(Re.I)': element.re,
          'Inspection type(I.I)': element.ii,
          'Load test(M)': element.m,
          'Load test(W)': element.w,
          'Load test(P)': element.p,
          'Inspection request received date': element.inspReqDate,
          'Inspection performed date': element.inspectionDate,
          'Next inspection due date': element.expiryDate,
          'Inspection result(P)': element.presult,
          'Inspection result(F)': element.fresult,
          'Inspector assigned': element.inspName,
          'Inspector certification number': element.certificateNo,
          'Sticker number': element.stickerNo,
          'QRCode': element.qrCode1,
          'Report number': element.reportNo,
        };
        i = i + 1;
        data1.push(val);
      });
      if(data1.length > 0){
        this.excelService.saveAsExcelFileForExternal(data1, this.companyData, this.countData, 'Weekly report');
      }
      else{
        this.ngZone.run(() => {
          setTimeout(() => {
            this.snackbar.open('No records found','', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          }, 0);
        });
      }

    } else {
      var data1 = [];
      var i = 1;
      this.companyData['trackingSystem'] = 'Contractor Cranes Weekly Tracking System';
      this.temp.forEach(element => {
        var val = {
          "SEQ no": i,
          "TEL no": element.tel,
          'Equipment owner': element.equOwner,
          'Equipment type': element.equType,
          'Equipment identification number': element.equIdNum,
          'Equipment serial number': element.equipmentSerialNumber,
          'Equipment location': element.equLoc,
          'Area': element.area,
          'Inspection type(P.I)': element.pi,
          'Inspection type(Re.I)': element.re,
          'Inspection type(I.I)': element.ii,
          'Load test(M)': element.m,
          'Load test(W)': element.w,
          'Load test(P)': element.p,
          'Inspection request received date': element.inspReqDate,
          'Inspection performed date': element.inspectionDate,
          'Next inspection due date': element.expiryDate,
          'Inspection result(P)': element.presult,
          'Inspection result(F)': element.fresult,
          'Inspector assigned': element.inspName,
          'Inspector certification number': element.certificateNo,
          'Sticker number': element.stickerNo,
          'QRCode': element.qrCode1,
          'Report number': element.reportNo,
        };
        i = i + 1;
        data1.push(val);
      });
      if(data1.length > 0){
        this.excelService.saveAsExcelFileForExternal(data1, this.companyData, this.countData, 'Weekly report');
      }
      else{
        this.ngZone.run(() => {
          setTimeout(() => {
            this.snackbar.open('No records found','', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          }, 0);
        });
      }
    }
  }

}
